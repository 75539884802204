import React from 'react';

import * as styles from './index.module.css';

const TermDescription = ({ description }) => {
  return (
    <div>
      <p className={styles['description']}>{description.description}</p>
    </div>
  );
};

export default TermDescription;
