import React from 'react';

import * as styles from './index.module.css';

const TermReference = ({ referenceUrl }) => {
  return (
    <div className={styles['root']}>
      <span className={styles['label']}>参考:</span>
      <a
        className={styles['anchor']}
        href={referenceUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {referenceUrl}
      </a>
    </div>
  );
};

export default TermReference;
