import React from 'react';

import * as styles from './index.module.css';

const TermRelatedTermsHeading = () => {
  return (
    <div>
      <h2 className={styles['heading']}>関連用語</h2>
    </div>
  );
};

export default TermRelatedTermsHeading;
