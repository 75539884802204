import React from 'react';

import ObjectFitCoverImage from '../../common/ObjectFitCoverImage';

import * as styles from './index.module.css';

const TermFigure = ({ figure }) => {
  return (
    <div className={styles['root']}>
      <ObjectFitCoverImage src={figure.resize.src} alt="" />
    </div>
  );
};

export default TermFigure;
