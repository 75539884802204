import React from 'react';

import * as styles from './index.module.css';

const TermTitle = ({ word }) => {
  return (
    <div>
      <h1 className={styles['word']}>{word}</h1>
    </div>
  );
};

export default TermTitle;
