// extracted by mini-css-extract-plugin
export var aside = "index-module--aside--hNJ-r";
export var contentArea = "index-module--content-area--GCz1h";
export var contentAreaInner = "index-module--content-area-inner--43Yz4";
export var figureWrapper = "index-module--figure-wrapper--xafjE";
export var main = "index-module--main--SI8jB";
export var nav = "index-module--nav--TY7jZ";
export var relatedTermsHeaderWrapper = "index-module--related-terms-header-wrapper--hHMt8";
export var relatedTermsWrapper = "index-module--related-terms-wrapper--K4HQN";
export var sectionHeadingWrapper = "index-module--section-heading-wrapper--qbUKS";
export var termReferenceWrapper = "index-module--term-reference-wrapper--EDFrj";
export var wordWrapper = "index-module--word-wrapper--xPBVX";