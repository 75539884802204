import React from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import PublicRoot from '../../components/PublicRoot';
import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';
import ContainerDefault from '../../components/ContainerDefault';

import ArticleListSmall from '../../organisms/ArticleListSmall';
import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';

import TermWord from '../../molecules/term/TermWord';
import TermFigure from '../../molecules/term/TermFigure';
import TermDescription from '../../molecules/term/TermDescription';
import TermReference from '../../molecules/term/TermReference';
import TermRelatedTermsHeading from '../../molecules/term/TermRelatedTermsHeading';
import TermRelatedTermLinkGroup from '../../molecules/term/TermRelatedTermLinkGroup';

import Breadcrumb from '../../molecules/common/Breadcrumb';
import SectionHeading from '../../molecules/common/SectionHeading';

import * as styles from './index.module.css';

const TermTemplate = ({ pageContext }) => {
  const { pagePath, term, relatedArticles } = pageContext;

  const breadcrumbItems = [
    {
      to: '/',
      label: 'ホーム',
    },
    {
      to: '/term',
      label: '用語集',
    },
    {
      to: `/term/${term.slug}`,
      label: term.word,
    },
  ];

  return (
    <PublicRoot>
      <SEO
        path={pagePath}
        title={term.word}
        description={term.description.description}
      />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <nav className={styles['nav']}>
              <ContainerDefault>
                <Breadcrumb items={breadcrumbItems} />
              </ContainerDefault>
            </nav>

            <article>
              <ContainerDefault>
                <div className={styles['contentArea']}>
                  <div className={styles['contentAreaInner']}>
                    <div className={styles['wordWrapper']}>
                      <TermWord word={term.word} />
                    </div>

                    {term.figure && (
                      <div className={styles['figureWrapper']}>
                        <TermFigure figure={term.figure} />
                      </div>
                    )}

                    <div>
                      <TermDescription description={term.description} />
                    </div>

                    {term.referenceUrl && (
                      <div className={styles['termReferenceWrapper']}>
                        <TermReference referenceUrl={term.referenceUrl} />
                      </div>
                    )}

                    {term.relatedTerms && (
                      <div className={styles['relatedTermsWrapper']}>
                        <div className={styles['relatedTermsHeaderWrapper']}>
                          <TermRelatedTermsHeading />
                        </div>
                        <div>
                          <TermRelatedTermLinkGroup terms={term.relatedTerms} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </ContainerDefault>
            </article>
          </main>

          {relatedArticles.length > 0 && (
            <aside className={styles['aside']}>
              <section>
                <ContainerDefault>
                  <div className={styles['sectionHeadingWrapper']}>
                    <SectionHeading heading="この用語が使われている記事" />
                  </div>

                  <div>
                    <ArticleListSmall articles={relatedArticles} />
                  </div>
                </ContainerDefault>
              </section>
            </aside>
          )}
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </PublicRoot>
  );
};

export default TermTemplate;
